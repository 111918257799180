import { PermissionCheckMixin, VIEW_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY, STERILIZATION_SPECIFICATIONS_REPORTS, STERILIZATION_SPECIFICATIONS_BRITESTACK, STERILIZATION_SPECIFICATIONS_STACKINGPATTERN, STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES, STERILIZATION_SPECIFICATIONS_CONTAINERSIZES, STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES, STERILIZATION_SPECIFICATIONS_FILLTYPES, STERILIZATION_SPECIFICATIONS_FUNCTIONS, STERILIZATION_SPECIFICATIONS_AUDITLOGS, AuthTypes } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body h-100" }, [_c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0" }, [_vm._v("STERILISATION")]), _c("h5", { staticClass: "mb-0" }, [_vm._v("Specifications")])]), _c("a-menu", { attrs: { "open-keys": _vm.openKeys, "mode": "inline", "theme": "light", "inline-collapsed": _vm.collapsed, "selected-keys": _vm.selectedKeys } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 && (item.key === "home" || _vm.$can(_vm.permissions.view, item.page)) ? [_c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.clickItem($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)] : _vm._e(), item.child.length > 0 ? [_c("a-sub-menu", { key: item.key, on: { "titleClick": function($event) {
      return _vm.clickParentItem($event);
    } } }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(itemChild) {
      return [_vm.$can(_vm.permissions.view, itemChild.page) ? _c("a-menu-item", { key: itemChild.key, on: { "click": function($event) {
        return _vm.clickItem($event);
      } } }, [_c("router-link", { attrs: { "to": itemChild.path } }, [_vm._v(_vm._s(itemChild.title))])], 1) : _vm._e()];
    })], 2)] : _vm._e()];
  })], 2)], 1), _c("router-view", { class: _vm.isPermissionsLoading ? "hide" : "" })], 1);
};
var staticRenderFns = [];
var SterilizationSpecifications_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "SterilizationSpecifications",
  mixins: [PermissionCheckMixin],
  data() {
    return {
      collapsed: false,
      permissions: {
        view: VIEW_PERMISSION
      },
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Sterilisation Data",
          path: "/sterilization-specifications/data-entries",
          key: "data-entries",
          icon: "form",
          page: STERILIZATION_SPECIFICATIONS_DATA_ENTRY,
          child: []
        },
        {
          title: "Reports",
          path: "/sterilization-specifications/reports",
          key: "reports",
          icon: "file-text",
          page: STERILIZATION_SPECIFICATIONS_REPORTS,
          child: []
        },
        {
          title: "Master Files",
          path: "",
          key: "masterfiles",
          icon: "tool",
          child: [
            {
              title: "Britestack",
              path: "/sterilization-specifications/britestacks",
              key: "britestacks",
              page: STERILIZATION_SPECIFICATIONS_BRITESTACK
            },
            {
              title: "Stacking Pattern",
              path: "/sterilization-specifications/stacking-pattern",
              key: "stacking-pattern",
              page: STERILIZATION_SPECIFICATIONS_STACKINGPATTERN
            },
            {
              title: "Cooker Capacities",
              path: "/sterilization-specifications/cooker-capacities",
              key: "cooker-capacities",
              page: STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES
            },
            {
              title: "Container Sizes",
              path: "/sterilization-specifications/container-sizes",
              key: "container-sizes",
              page: STERILIZATION_SPECIFICATIONS_CONTAINERSIZES
            },
            {
              title: "Hydro Section Times",
              path: "/sterilization-specifications/hydro-section-times",
              key: "hydro-section-times",
              page: STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES
            },
            {
              title: "Fill Types",
              path: "/sterilization-specifications/fill-types",
              key: "fill-types",
              page: STERILIZATION_SPECIFICATIONS_FILLTYPES
            },
            {
              title: "Functions",
              path: "/sterilization-specifications/functions",
              key: "functions",
              page: STERILIZATION_SPECIFICATIONS_FUNCTIONS
            }
          ]
        },
        {
          title: "Audit Logs",
          path: "/sterilization-specifications/audit-logs",
          key: "audit-logs",
          icon: "history",
          page: STERILIZATION_SPECIFICATIONS_AUDITLOGS,
          child: []
        }
      ],
      selectedKeys: [],
      openKeys: [],
      rootSubmenuKeys: []
    };
  },
  metaInfo: {
    title: "Specifications"
  },
  computed: {
    isPermissionsLoading() {
      const { namespace, AUTH_GET_OPERATIONS } = AuthTypes;
      const isLoading = Object.keys(this.$store.getters[`${namespace}/${AUTH_GET_OPERATIONS}`]).length === 0;
      return isLoading;
    },
    currentRouteInfo() {
      if (!this.$route.matched[1])
        return {};
      const { path, meta } = this.$route.matched[1];
      return { path, meta };
    }
  },
  watch: {
    currentRouteInfo(newVal) {
      this.updateMenu(newVal);
    }
  },
  created() {
    this.$context.setSteriSpecsContext();
    this.updateMenu(this.currentRouteInfo);
  },
  methods: {
    updateMenu({ path, meta }) {
      this.openKeys = typeof meta == "string" ? [meta.split(";")[0]] : [];
      const title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    clickItem: function(e) {
      this.selectedKeys = [e.key];
    },
    clickParentItem(e) {
      if (!this.openKeys.includes(e.key)) {
        this.openKeys = [e.key];
      } else {
        const keyIndex = this.openKeys.indexOf(e.key);
        if (keyIndex > -1)
          this.openKeys.splice(keyIndex, 1);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "f2789088", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var SterilizationSpecifications = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { SterilizationSpecifications as default };
